import { cn } from '@/app/utils/cn';
import { useState, useEffect } from 'react';

interface VersionInfoProps {
    className?: string;
}

const VersionInfo = ({ className }: VersionInfoProps) => {
    const [version, setVersion] = useState('unknown');
    const [build, setBuild] = useState('unknown');

    useEffect(() => {
        const fetchVersion = () => {
            try {
                const packageInfo = require('../../../../package.json');
                setVersion(packageInfo.version);
            } catch (error) {
                console.error('No se pudo obtener la versión del producto', error);
            }
        };

        fetchVersion();

        setBuild(process.env.NEXT_PUBLIC_BITBUCKET_BUILD_NUMBER || 'local');
    }, []);

    return (
        <div
            className={cn(
                'text-sm text-left font-thin leading-normal p-8 pl-12 absolute bottom-0 left-0 w-full',
                className
            )}
        >
            <p>Version: {version}</p>
            <p>Build: {build}</p>
        </div>
    );
};

export default VersionInfo;
