import {
    Client,
    ClientPlan,
    ShopifyIntegration,
    TiendanubeIntegration,
    User
} from '@prisma/client';

import { PaginatedData } from '@/types/paginatedData';

import { BaseResponse, HttpRequest } from './fetch-methods';
import generateUrl from './utils/url-generator';
import { BaseService } from './base-service';
import { ClientUser } from '../pages/clients/client-form/utils/client-form-data-manager';

export type ClientWithEmails = Client & { users: { email: string }[] };

export interface ClientService {
    getClient(id: string): Promise<BaseResponse<Client>>;
    getAllClients(): Promise<BaseResponse<PaginatedData<Client>>>;
    getClients(criteria?: {
        page?: number;
        pageSize?: number;
        search?: string;
        id?: string;
        withEmails?: boolean;
        organizationSize?: string;
    }): Promise<BaseResponse<PaginatedData<Client | ClientWithEmails>>>;
    postClient(
        name: string,
        timeOffset: number,
        whatsappId?: number,
        facebookPageId?: string,
        clientLogo?: Blob,
        planId?: string
    ): Promise<BaseResponse<Client>>;
    putClient(
        id: string,
        name: string,
        timeOffset: number,
        whatsappId?: number,
        facebookPageId?: string,
        clientLogo?: Blob,
        planId?: string
    ): Promise<BaseResponse<Client>>;
    deleteClient(clientId: string): Promise<BaseResponse<void>>;

    getPlan(clientId: string): Promise<BaseResponse<ClientPlan>>;
    getClientUsers(clientId: string): Promise<BaseResponse<{ users: User[] }>>;
    postClientUser(clientId: string, user: ClientUser): Promise<BaseResponse<User>>;

    postTiendanubeIntegration(
        clientId: string,
        data: {
            code?: string;
            iframeWidth?: number;
            iframeHeight?: number;
            ingestProducts?: boolean;
        }
    ): Promise<BaseResponse<TiendanubeIntegration>>;

    postShopifyIntegration(
        clientId: string,
        installationData?: Record<string, string | undefined>,
        configData?: { ingestProducts?: boolean }
    ): Promise<BaseResponse<ShopifyIntegration | { redirectTo: string }>>;
}

export class ClientServiceImp extends BaseService implements ClientService {
    private httpRequest: HttpRequest;

    constructor(httpRequest: HttpRequest) {
        super('Client-Service');
        this.httpRequest = httpRequest;
    }

    async getClient(id: string): Promise<BaseResponse<Client>> {
        return this.tryRequest<Client>(() =>
            this.httpRequest.get<Client>({
                url: generateUrl('api/client/' + id)
            })
        );
    }

    async getClients(criteria?: {
        page?: number;
        pageSize?: number;
        search?: string;
        id?: string;
        withEmails?: boolean;
        organizationSize?: string;
    }): Promise<BaseResponse<PaginatedData<Client | ClientWithEmails>>> {
        return this.tryRequest(() =>
            this.httpRequest.get<PaginatedData<Client | ClientWithEmails>>({
                url: generateUrl('api/client', criteria)
            })
        );
    }

    async getAllClients(): Promise<BaseResponse<PaginatedData<Client>>> {
        return this.getClients({ pageSize: Number.MAX_SAFE_INTEGER });
    }

    async postClient(
        name: string,
        timeOffset: number,
        whatsappId?: number,
        facebookPageId?: string,
        clientLogo?: Blob | null,
        planId?: string
    ): Promise<BaseResponse<Client>> {
        const form = new FormData();

        form.append('name', name);
        form.append('timeOffset', timeOffset.toString());

        if (planId) form.append('planId', planId);
        if (whatsappId) form.append('whatsappId', whatsappId.toString());
        if (facebookPageId) form.append('facebookPageId', facebookPageId);
        if (clientLogo !== undefined) form.append('clientLogo', clientLogo || 'null');

        return await this.tryRequest<Client>(async () =>
            this.httpRequest.post<Client>({
                url: generateUrl('api/client'),
                body: form
            })
        );
    }

    async putClient(
        id: string,
        name: string,
        timeOffset: number,
        whatsappId?: number,
        facebookPageId?: string,
        clientLogo?: Blob | null,
        planId?: string
    ): Promise<BaseResponse<Client>> {
        const form = new FormData();

        form.append('name', name);
        form.append('timeOffset', timeOffset.toString());

        if (planId) form.append('planId', planId);
        if (whatsappId) form.append('whatsappId', whatsappId.toString());
        if (facebookPageId) form.append('facebookPageId', facebookPageId);
        if (clientLogo !== undefined) form.append('clientLogo', clientLogo || 'null');

        return await this.tryRequest(async () =>
            this.httpRequest.put({
                url: generateUrl(`api/client/${id}`),
                body: form
            })
        );
    }

    deleteClient(clientId: string): Promise<BaseResponse<void>> {
        return this.tryRequest<void>(() =>
            this.httpRequest.delete<void>({
                url: generateUrl(`api/client/${clientId}`)
            })
        );
    }

    //maybe we could return the ClientPlanWrapper instead of ClientPlan
    getPlan(clientId: string): Promise<BaseResponse<ClientPlan>> {
        const request = () =>
            this.httpRequest.get<ClientPlan>({
                url: generateUrl(`api/client/${clientId}/plan`)
            });

        return this.tryRequest<ClientPlan>(request);
    }

    getClientUsers(clientId: string): Promise<BaseResponse<{ users: User[] }>> {
        return this.tryRequest<{ users: User[] }>(() =>
            this.httpRequest.get<{ users: User[] }>({
                url: generateUrl(`api/client/${clientId}/users`)
            })
        );
    }

    postClientUser(clientId: string, user: ClientUser): Promise<BaseResponse<User>> {
        return this.tryRequest<User>(() =>
            this.httpRequest.post<User>({
                url: generateUrl(`api/client/${clientId}/users`),
                body: { ...user, key: undefined }
            })
        );
    }

    postTiendanubeIntegration(
        clientId: string,
        data: {
            code?: string;
            iframeWidth?: number;
            iframeHeight?: number;
            ingestProducts?: boolean;
        }
    ): Promise<BaseResponse<TiendanubeIntegration>> {
        return this.tryRequest<TiendanubeIntegration>(() =>
            this.httpRequest.post<TiendanubeIntegration>({
                url: generateUrl(`api/client/${clientId}/integrations/tiendanube`),
                body: { ...data }
            })
        );
    }

    postShopifyIntegration(
        clientId: string,
        installationData?: Record<string, string | undefined>,
        configData?: { ingestProducts?: boolean }
    ): Promise<BaseResponse<ShopifyIntegration | { redirectTo: string }>> {
        return this.tryRequest(() =>
            this.httpRequest.post({
                url: generateUrl(`api/client/${clientId}/integrations/shopify`),
                body: { installationData, configData }
            })
        );
    }
}
